import * as React from "react"
import { FC } from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { smallMobile } from "../../constants/screenSizes"
import { lightGray } from "../../constants/colors"
import GLink from "../../../gatsby/GLink"
import FlipImage from "../../components/image/FlipImage"
import { BrandType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  margin-bottom: 10px;
  border: 1px solid ${lightGray};
  padding: 10px;
  display: flex;

  @media (max-width: ${smallMobile}px) {
    flex-wrap: wrap;
  }
`

const ImageContainer = styled.div`
  margin-right: 10px;
  width: 100px;
  @media (max-width: ${smallMobile}px) {
    width: 100%;
    margin-bottom: 10px;
  }
`

const StyledImg = styled(GatsbyImage)`
  width: 100px;
  height: 100px;
`

const Paragraph = styled.p`
  margin: 0;
  a {
    font-size: 0.8em;
  }
`

const ImpactLine = styled.p`
  margin-bottom: 0;
`

interface BrandPreviewProps {
  brand?: BrandType
}

const BrandPreview: FC<BrandPreviewProps> = ({ brand }): JSX.Element => {
  return (
    <Container>
      <ImageContainer>
        {brand.image && (
          <GLink to={`/brand/${brand.slug}`}>
            <FlipImage image={brand.image} />
          </GLink>
        )}
      </ImageContainer>
      <div>
        <GLink to={`/brand/${brand.slug}`}>
          <h5>{brand.name}</h5>
        </GLink>
        <ImpactLine>{brand.impactOneLine}</ImpactLine>
        <Paragraph>
          <GLink to={`/brand/${brand.slug}`}>View brand</GLink>
        </Paragraph>
      </div>
    </Container>
  )
}

export default BrandPreview
