import * as React from "react"
import { FC } from "react"
import { graphql } from "gatsby"
import { BrandQueryType } from "../gatsby/model/BrandType"
import { mapBrand } from "../gatsby/transformers/brandTransformer"
import BrandsPage from "../social-supermarket/pages/brand-az/BrandsPage"

const Brands: FC<BrandQueryType> = ({
  data: {
    allWpBrand: { nodes },
  },
}): JSX.Element => {
  const brands = nodes.map(mapBrand)

  return <BrandsPage brands={brands} />
}

export default Brands

export const query = graphql`
  {
    allWpBrand {
      nodes {
        id
        name
        slug
        image {
          ...UrlImageFragment
        }
        custom {
          impactOneLine
        }
      }
    }
  }
`
