import * as React from "react"
import { Fragment, FC, useState, useRef } from "react"
import styled from "styled-components"
import { groupBrands } from "../../../gatsby/transformers/brandTransformer"
import SEO from "../../../components/Seo"
import Layout from "../../../components/Layout"
import AZFilter from "../../components/a-z-filter/A-ZFilter"
import BrandPreview from "./BrandPreview"
import { largeDesktop } from "../../constants/screenSizes"
import { BrandType } from "@social-supermarket/social-supermarket-model"

const Container = styled.div`
  position: relative;
  margin: 20px auto auto;
  min-height: 1000px;

  @media (max-width: ${largeDesktop}px) {
    padding: 0 10px;
  }
`

interface Props {
  brands: BrandType[]
}

const BrandsPage: FC<Props> = ({ brands }): JSX.Element => {
  const [searchValue, setSearchValue] = useState<string>("")
  const groupedBrands = groupBrands(brands, searchValue)
  const usedLetters: string[] = Object.keys(groupedBrands)
  usedLetters.sort()

  const refs = useRef({})

  const handleSearch = searchValue => {
    setSearchValue(searchValue)
  }

  const handleLetterClick = letter => {
    window.scrollTo({
      top: refs.current[letter].offsetTop,
      left: 0,
      behavior: "smooth",
    })
  }

  return (
    <Layout fullWidth={false}>
      <SEO title={"Social Supermarket Brands"} description={"Social Supermarket Brands"} />
      <AZFilter
        usedLetters={usedLetters}
        onSearch={handleSearch}
        onLetterClick={handleLetterClick}
      />
      <Container>
        {usedLetters.length < 1 && <div>No results</div>}
        {usedLetters.map(letter => (
          <Fragment key={letter}>
            <h2 ref={el => (refs.current[letter] = el)}>{letter}</h2>
            {groupedBrands[letter].map(brand => (
              <BrandPreview key={brand.slug} brand={brand} />
            ))}
          </Fragment>
        ))}
      </Container>
    </Layout>
  )
}

export default BrandsPage
